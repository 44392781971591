<template>
  <div class="values container">
    <div class="introduction">
      <h3>{{ $t('values.heading') }}</h3>
      <p>{{ $t('values.description') }}</p>
    </div>
    <van-form
      validate-first
      @submit="finishOnboarding"
    >
      <field-list
        dark
        class="authentication-form__fields"
      >
        <van-field
          v-if="!isNutritionKeyProtein(diagnosis)"
          v-model="diagnosisValue"
          type="number"
          :label="diagnosis.getLabel()"
          :placeholder="$t('values.requirements.diagnosis', [
            $t(`nutritions.${diagnosis.nutritionKey}`),
          ])"
          :rules="[
            { required: true, message: $t('values.validation.required', [diagnosis.name]) },
            { validator: numberValidator, message: $t('values.validation.positive') },
          ]"
        />
        <van-checkbox
          v-if="diagnosis.optionalNutritionKeys"
          v-model="useOptionalNutritionValues"
        >
          {{ $t('auth.changeDiagnosis.optionalValues') }}
        </van-checkbox>
        <template v-if="useOptionalNutritionValues">
          <van-field
            v-for="nutritionKey in diagnosis.optionalNutritionKeys"
            :key="nutritionKey"
            v-model="optionalDiagnosisValues[nutritionKey]"
            type="number"
            :label="diagnosis.getLabel(true, nutritionKey)"
            :placeholder="$t('values.requirements.diagnosis', [
              $t(`nutritions.${nutritionKey}`),
            ])"
            :rules="[
              { validator: numberValidator, message: $t('values.validation.positive') },
            ]"
          />
        </template>
        <van-field
          v-model="proteinValue"
          type="number"
          :label="`${$t('protein')} (${getNutritionUnit('protein')})`"
          :placeholder="$t('values.requirements.protein')"
          :rules="[
            {
              required: isNutritionKeyProtein(diagnosis),
              message: $t('values.validation.required', [$t('protein')]),
            },
            { validator: numberValidator, message: $t('values.validation.positive') },
          ]"
        />
        <van-field
          v-model="caloriesValue"
          type="number"
          :label="`${$t('calories')} (${getNutritionUnit('calories')})`"
          :placeholder="$t('values.requirements.calories')"
          :rules="[
            { required: true, message: $t('values.validation.required', [$t('calories')]) },
            { validator: numberValidator, message: $t('values.validation.positive') },
          ]"
        />
      </field-list>
      <infobox
        class="disclaimer"
        :content="$t('values.disclaimer')"
        :icon="$icon('info-purple')"
        dark
        outline
      />
      <a
        class="guidelines-link"
        @click="showGuidelines = true"
      >
        {{ $t('guidelines.link') }}
      </a>
      <van-button
        type="white"
        :text="$t('save')"
      />
    </van-form>
    <guidelines
      :show="showGuidelines"
      @hide="showGuidelines = false"
    />
  </div>
</template>

<script>
import { positiveNumberValidator } from '@/validators';
import { auth } from '@/firebase';
import { format } from 'date-fns';
import Diagnosis from '@/models/diagnosis';
import UserProfile from '@/models/user-profile';
import FieldList from '@/components/FieldList';
import Guidelines from '@/components/Guidelines/Guidelines.vue';
import Infobox from '@/components/Infobox';
import { getNutritionUnit, isNutritionKeyProtein } from '@/helper';

export default {
  components: {
    FieldList,
    Infobox,
    Guidelines,
  },
  data() {
    return {
      proteinValue: null,
      caloriesValue: null,
      diagnosisValue: null,
      showGuidelines: false,
      useOptionalNutritionValues: false,
      optionalDiagnosisValues: {},
    };
  },
  created() {
    this.diagnosis = new Diagnosis(this.$store.getters['user/diagnosisName']);
  },
  methods: {
    finishOnboarding() {
      const nutritionLimits = {
        protein: this.$helper.convertNumber(this.proteinValue),
        calories: this.$helper.convertNumber(this.caloriesValue),
        [this.diagnosis.nutritionKey]: this.$helper.convertNumber(this.diagnosisValue),
        diagnosisName: this.diagnosis.name,
      };

      if (this.optionalDiagnosisValues) {
        Object.entries(this.optionalDiagnosisValues).forEach(([name, value]) => {
          nutritionLimits[name] = this.$helper.convertNumber(value);
        });
      }

      const limits = { [format(Date.now(), 'yyyy-MM-dd')]: { ...nutritionLimits } };

      this.$store.commit('user/setOptionalNutritionValues', !!this.useOptionalNutritionValues);
      this.$store.commit('user/setNutritionLimits', nutritionLimits);
      this.$store.commit('user/setSavedNutritionLimits', limits);

      auth.onAuthStateChanged(async (user) => {
        const userProfile = new UserProfile(user.uid);

        userProfile.create({
          onboardingCompleted: true,
          nutritionLimits,
          savedNutritionLimits: limits,
          diagnosisName: this.diagnosis.name,
        }).then(() => {
          this.$router.push({ name: 'dashboard' });
        });
      });
    },
    numberValidator(value) {
      return positiveNumberValidator(value);
    },
    getNutritionUnit,
    isNutritionKeyProtein,
  },
};
</script>

<style lang="scss" scoped>
@use '~@/styles/config' as config;
.values {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}
.van-field {
  flex-direction: column;
  padding-right: 0;
  padding-left: 0;
}
.introduction {
  margin-bottom: config.$spacing-xl;
  text-align: center;
}
.disclaimer {
  margin: config.$spacing-xxl 0;
  text-align: left;
}
.guidelines-link {
  text-decoration: underline;
}
.van-button {
  margin: config.$spacing 0;
}
</style>
