<template>
  <van-popup
    v-model="show"
    class="guidelines"
    closeable
    close-icon="close"
    position="bottom"
    @close="$emit('hide', false)"
  >
    <div class="container">
      <h2>{{ $t('guidelines.title') }}</h2>
      <table>
        <thead>
          <tr>
            <th>{{ $t('guidelines.age') }}</th>
            <th>{{ $t('male') }}</th>
            <th>{{ $t('female') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr><td>1 - &lt;4</td><td>1200</td><td>1100</td></tr>
          <tr><td>4 - &lt;7</td><td>1400</td><td>1300</td></tr>
          <tr><td>7 - &lt;10</td><td>1700</td><td>1500</td></tr>
          <tr><td>10 - &lt;13</td><td>1900</td><td>1700</td></tr>
          <tr><td>13 - &lt;15</td><td>2300</td><td>1900</td></tr>
          <tr><td>15 - &lt;19</td><td>2600</td><td>2000</td></tr>
          <tr><td>19 - &lt;25</td><td>2400</td><td>1900</td></tr>
          <tr><td>25 - &lt;51</td><td>2300</td><td>1800</td></tr>
          <tr><td>51 - &lt;65</td><td>2200</td><td>1700</td></tr>
          <tr><td>{{ $t('guidelines.older') }}</td><td>2100</td><td>1700</td></tr>
        </tbody>
      </table>
      <p>{{ $t('guidelines.disclaimer') }}</p>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
@use '~@/styles/config' as config;

.guidelines {
  height: 100%;
  color: config.$color-darker;
  text-align: left;
}

.container {
  padding: 19px 27px;
}

h2 {
  margin: 0 3.3333em 1.6666em;
  text-align: center;
  font-size: 0.9375rem;
  font-weight: bold;
}

table {
  width: 100%;
  margin-bottom: 1.875rem;
  border-collapse: collapse;
}

th,
td {
  padding: 6px 8px;
  border-bottom: 1px solid config.$color-mid-light;
}

p {
  font-size: 13px;
  line-height: 1.38;
}
</style>
