var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"values container"},[_c('div',{staticClass:"introduction"},[_c('h3',[_vm._v(_vm._s(_vm.$t('values.heading')))]),_c('p',[_vm._v(_vm._s(_vm.$t('values.description')))])]),_c('van-form',{attrs:{"validate-first":""},on:{"submit":_vm.finishOnboarding}},[_c('field-list',{staticClass:"authentication-form__fields",attrs:{"dark":""}},[(!_vm.isNutritionKeyProtein(_vm.diagnosis))?_c('van-field',{attrs:{"type":"number","label":_vm.diagnosis.getLabel(),"placeholder":_vm.$t('values.requirements.diagnosis', [
          _vm.$t(`nutritions.${_vm.diagnosis.nutritionKey}`),
        ]),"rules":[
          { required: true, message: _vm.$t('values.validation.required', [_vm.diagnosis.name]) },
          { validator: _vm.numberValidator, message: _vm.$t('values.validation.positive') },
        ]},model:{value:(_vm.diagnosisValue),callback:function ($$v) {_vm.diagnosisValue=$$v},expression:"diagnosisValue"}}):_vm._e(),(_vm.diagnosis.optionalNutritionKeys)?_c('van-checkbox',{model:{value:(_vm.useOptionalNutritionValues),callback:function ($$v) {_vm.useOptionalNutritionValues=$$v},expression:"useOptionalNutritionValues"}},[_vm._v(" "+_vm._s(_vm.$t('auth.changeDiagnosis.optionalValues'))+" ")]):_vm._e(),(_vm.useOptionalNutritionValues)?_vm._l((_vm.diagnosis.optionalNutritionKeys),function(nutritionKey){return _c('van-field',{key:nutritionKey,attrs:{"type":"number","label":_vm.diagnosis.getLabel(true, nutritionKey),"placeholder":_vm.$t('values.requirements.diagnosis', [
            _vm.$t(`nutritions.${nutritionKey}`),
          ]),"rules":[
            { validator: _vm.numberValidator, message: _vm.$t('values.validation.positive') },
          ]},model:{value:(_vm.optionalDiagnosisValues[nutritionKey]),callback:function ($$v) {_vm.$set(_vm.optionalDiagnosisValues, nutritionKey, $$v)},expression:"optionalDiagnosisValues[nutritionKey]"}})}):_vm._e(),_c('van-field',{attrs:{"type":"number","label":`${_vm.$t('protein')} (${_vm.getNutritionUnit('protein')})`,"placeholder":_vm.$t('values.requirements.protein'),"rules":[
          {
            required: _vm.isNutritionKeyProtein(_vm.diagnosis),
            message: _vm.$t('values.validation.required', [_vm.$t('protein')]),
          },
          { validator: _vm.numberValidator, message: _vm.$t('values.validation.positive') },
        ]},model:{value:(_vm.proteinValue),callback:function ($$v) {_vm.proteinValue=$$v},expression:"proteinValue"}}),_c('van-field',{attrs:{"type":"number","label":`${_vm.$t('calories')} (${_vm.getNutritionUnit('calories')})`,"placeholder":_vm.$t('values.requirements.calories'),"rules":[
          { required: true, message: _vm.$t('values.validation.required', [_vm.$t('calories')]) },
          { validator: _vm.numberValidator, message: _vm.$t('values.validation.positive') },
        ]},model:{value:(_vm.caloriesValue),callback:function ($$v) {_vm.caloriesValue=$$v},expression:"caloriesValue"}})],2),_c('infobox',{staticClass:"disclaimer",attrs:{"content":_vm.$t('values.disclaimer'),"icon":_vm.$icon('info-purple'),"dark":"","outline":""}}),_c('a',{staticClass:"guidelines-link",on:{"click":function($event){_vm.showGuidelines = true}}},[_vm._v(" "+_vm._s(_vm.$t('guidelines.link'))+" ")]),_c('van-button',{attrs:{"type":"white","text":_vm.$t('save')}})],1),_c('guidelines',{attrs:{"show":_vm.showGuidelines},on:{"hide":function($event){_vm.showGuidelines = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }